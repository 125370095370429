/*! source instant.page v5.2.0 - (C) 2019-2024 Alexandre Dieulot - https://instant.page/license */

'use strict';

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
(function ($) {
  T.Utils = T.Utils || {};
  T.Utils.PerfPrefetch = {
    _chromiumMajorVersionInUserAgent: null,
    _speculationRulesType: 'none',
    _delayOnHover: 65,
    _lastTouchstartEvent: null,
    _mouseoverTimer: null,
    _preloadedList: new Set(),
    isNavigatorConnectionAdequate: false,
    _nonce: '',
    init: function init() {
      var $scriptElem = $('#perf');
      this._nonce = $scriptElem.attr('data-config');
      $scriptElem.attr('data-config', undefined);
      if (!this.hasSupport()) {
        return;
      }
      this.setSpeculationType();
      this.addEventListeners();
    },
    hasSupport: function hasSupport() {
      var isNavigatorConnectionSaveDataEnabled = navigator.connection && navigator.connection.saveData;
      var isNavigatorConnectionLike2g = navigator.connection && navigator.connection.effectiveType && navigator.connection.effectiveType.includes('2g');
      this.isNavigatorConnectionAdequate = !isNavigatorConnectionSaveDataEnabled && !isNavigatorConnectionLike2g;
      if (!this.isNavigatorConnectionAdequate) {
        return false;
      }
      var supportCheckRelList = document.createElement('link').relList;
      var isSupported = supportCheckRelList.supports('prefetch') && supportCheckRelList.supports('modulepreload');
      if (!isSupported) {
        return false;
      }

      // The `Vary: Accept` header when received in Chromium 79–109 makes prefetches
      // unusable, as Chromium used to send a different `Accept` header.
      var chromiumUserAgentIndex = navigator.userAgent.indexOf('Chrome/');
      if (chromiumUserAgentIndex > -1) {
        this._chromiumMajorVersionInUserAgent = parseInt(navigator.userAgent.substring(chromiumUserAgentIndex + 'Chrome/'.length));
      }
      return !(this._chromiumMajorVersionInUserAgent && this._chromiumMajorVersionInUserAgent < 110);
    },
    setSpeculationType: function setSpeculationType() {
      if (HTMLScriptElement.supports && HTMLScriptElement.supports('speculationrules')) {
        this._speculationRulesType = 'prefetch';
      } else {
        this._speculationRulesType = 'none';
      }
    },
    addEventListeners: function addEventListeners() {
      var eventListenersOptions = {
        capture: true,
        passive: true
      };
      document.addEventListener('touchstart', this.touchstartListener.bind(this), eventListenersOptions);
      document.addEventListener('mouseover', this.mouseoverListener.bind(this), eventListenersOptions);
    },
    touchstartListener: function touchstartListener(event) {
      this._lastTouchstartEvent = event;
      var anchorElement = event.target.closest('a');
      if (!this.isPreloadable(anchorElement)) {
        return;
      }
      this.preload(anchorElement.href, 'high');
    },
    mouseoverListener: function mouseoverListener(event) {
      var _this = this;
      if (this.isEventLikelyTriggeredByTouch(event)) {
        return;
      }
      if (!('closest' in event.target)) {
        return;
      }
      var anchorElement = event.target.closest('a');
      if (!this.isPreloadable(anchorElement)) {
        return;
      }
      anchorElement.addEventListener('mouseout', this.mouseoutListener, {
        passive: true
      });
      this._mouseoverTimer = setTimeout(function () {
        _this.preload(anchorElement.href, 'high');
        _this._mouseoverTimer = null;
      }, this._delayOnHover);
    },
    mouseoutListener: function mouseoutListener(event) {
      if (event.relatedTarget && event.target.closest('a') === event.relatedTarget.closest('a')) {
        return;
      }
      if (this._mouseoverTimer) {
        clearTimeout(this._mouseoverTimer);
        this._mouseoverTimer = null;
      }
    },
    isEventLikelyTriggeredByTouch: function isEventLikelyTriggeredByTouch(event) {
      if (!this._lastTouchstartEvent || !event) {
        return false;
      }
      if (event.target !== this._lastTouchstartEvent.target) {
        return false;
      }
      var now = event.timeStamp;
      var durationBetweenLastTouchstartAndNow = now - this._lastTouchstartEvent.timeStamp;
      var MAX_DURATION_TO_BE_CONSIDERED_TRIGGERED_BY_TOUCHSTART = 2500;
      return durationBetweenLastTouchstartAndNow < MAX_DURATION_TO_BE_CONSIDERED_TRIGGERED_BY_TOUCHSTART;
    },
    isPreloadable: function isPreloadable(anchorElement) {
      if (!anchorElement || !anchorElement.href) {
        return false;
      }
      if (anchorElement.origin !== location.origin) {
        return false;
      }
      if (!['http:', 'https:'].includes(anchorElement.protocol)) {
        return false;
      }
      return !(anchorElement.protocol === 'http:' && location.protocol === 'https:');
    },
    preload: function preload(url) {
      var fetchPriority = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'auto';
      if (this._preloadedList.has(url)) {
        return;
      }
      if (this._speculationRulesType !== 'none') {
        this.preloadUsingSpeculationRules(url);
      } else {
        this.preloadUsingLinkElement(url, fetchPriority);
      }
      this._preloadedList.add(url);
    },
    preloadUsingSpeculationRules: function preloadUsingSpeculationRules(url) {
      var scriptElement = document.createElement('script');
      scriptElement.setAttribute('nonce', this._nonce);
      scriptElement.type = 'speculationrules';
      scriptElement.textContent = JSON.stringify(_defineProperty({}, this._speculationRulesType, [{
        source: 'list',
        urls: [url]
      }]));
      document.head.appendChild(scriptElement);
    },
    preloadUsingLinkElement: function preloadUsingLinkElement(url) {
      var fetchPriority = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'auto';
      var linkElement = document.createElement('link');
      linkElement.rel = 'prefetch';
      linkElement.href = url;
      linkElement.setAttribute('nonce', this._nonce);
      linkElement.fetchPriority = fetchPriority;
      linkElement.as = 'document';
      document.head.appendChild(linkElement);
    }
  };
  T.Utils.PerfPrefetch.init();
})(jQuery);
/*! Generated on Wed Sep 11 2024 07:41:53 GMT+0000 (Coordinated Universal Time) */
/*! From Branch origin/release/release-V3.167.0 last commit 5cea9b0a57 */
